import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
            <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Our services 
                    </span>
                    <h2>We are here to help you</h2>
			        <p>We can provide different services to meet all your needs</p>
            </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/app-development">
	    				            App development
                                </Link>
                            </h3>
                            <p>We create web and mobile apps to spread your projects, engage your stakeholders, exchange information and manage data.</p>
                            <Link className="view-details-btn" to="/app-development">
                                Read more
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/digital-transformation">
                                Digital transformation 
                                </Link>
                            </h3>

                            <p>We support your organization towards an efficient and effective use of technology, through consultancy, training and the development of the IT solutions that best fit your needs. 
</p>
                            
                            <Link className="view-details-btn" to="/digital-transformation">
                            Read more
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/social-balance-monitoring-evaluation">
                                Social balance and Monitoring and Evaluation 
                                </Link>
                            </h3>

                            <p>Social reporting is made easy, with innovative tools and methodologies.

</p>
                            
                            <Link className="view-details-btn" to="/social-balance-monitoring-evaluation">
                            Read more
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/case-management">
                                Case management
                                </Link>
                            </h3>

                            <p>We support the management of the relationships with your beneficiaries, and their involvement in your activities. </p>
                           
                            <Link className="view-details-btn" to="/case-management">
                            Read more
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/data-analysis">
                                Data analysis
                                </Link>
                            </h3>

                            <p>We dig the mines of information composed by your data. You might not even be fully aware of the potential richness you have at your disposal, we still work hard to look for those information nuggets you surely have. 
</p>
                            
                            <Link className="view-details-btn" to="/data-analysis">
                            Read more
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/artificial-intelligence">
                                Artificial intelligence
                                </Link>
                            </h3>

                            <p>We apply Artificial Intelligence algorithm to create general models from your data and apply those models to your projects. Facial recognition, classification, decision making are only part of the applications you might find useful. 
</p>
                            
                            <Link className="view-details-btn" to="/artificial-intelligence">
                            Read more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne
