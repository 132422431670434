import React from 'react'
import {Link} from 'gatsby'
import projectStart from '../../assets/images/project-start1.png'
import shape from '../../assets/images/shape/circle-shape1.png'

const ProjectStartArea = () => {
    return (
        <div className="project-start-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <img src={projectStart} alt="project" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                        <h2>TOGETHER<br/>we can do more</h2>
                           <p>Do you have an idea and don't know what tools you could use to realize it?<br/>
                           Do you have a problem and don't know what technology you could use to tackle it? <br/>Contact us, we will be happy to talk together and share our experience.
</p> 
                            <Link to="/contatti" className="default-btn">
                                <i className="flaticon-web"></i> 
                                Contact us 
                                <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape} alt="project" />
            </div>
        </div>
    )
}

export default ProjectStartArea;
