import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        The Gnucoop Advantage 
                    </span>
                    <h2>Why choose Gnucoop?</h2>
                    <p>There are many companies that offer various types of IT services, so why choose Gnucoop? For at least three good reasons...</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
				    PARTNERSHIP
                            </h3>
                            <p>We prefer to consider ourselves partners rather than suppliers because we share the social values of the companies we work with and have the same objectives.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                            FLEXIBILITY
                            </h3>
                            <p>Living with uncertainty is the key to our time. This requires adaptability and willingness to redefine the perimeter of work according to changing external conditions.</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                            COMPETENCE
                            </h3> 

                           <p> We don't rest on known solutions, but we always try to experiment new ways. Technological development follows a furious pace, which is why we rigorously keep up to date on all the topics we deal with.</p> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;
